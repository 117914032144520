var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              { required: true, min: 1, message: '请输入 ai 对话平台名称！' } ],
            initialValue: _vm.record ? _vm.record.name : undefined,
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              { required: true, min: 1, message: '请输入 ai 对话平台名称！' },\n            ],\n            initialValue: record ? record.name : undefined,\n          },\n        ]"}],attrs:{"placeholder":"请输入 ai 对话平台名称"}})],1),_c('a-form-item',{attrs:{"label":"链接","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'url',
          {
            rules: [
              {
                required: true,
                min: 1,
                message: '请输入 ai 对话平台链接！',
              } ],
            initialValue: _vm.record
              ? _vm.record.url.startsWith('http')
                ? _vm.record.url.split('//')[1]
                : _vm.record.url
              : undefined,
          } ]),expression:"[\n          'url',\n          {\n            rules: [\n              {\n                required: true,\n                min: 1,\n                message: '请输入 ai 对话平台链接！',\n              },\n            ],\n            initialValue: record\n              ? record.url.startsWith('http')\n                ? record.url.split('//')[1]\n                : record.url\n              : undefined,\n          },\n        ]"}],attrs:{"placeholder":"请输入 ai 对话平台链接"}},[_c('a-select',{attrs:{"slot":"addonBefore"},slot:"addonBefore",model:{value:(_vm.protocol),callback:function ($$v) {_vm.protocol=$$v},expression:"protocol"}},[_c('a-select-option',{attrs:{"value":"https://"}},[_vm._v(" https:// ")]),_c('a-select-option',{attrs:{"value":"http://"}},[_vm._v(" http:// ")])],1)],1)],1),_c('a-form-item',{attrs:{"label":"简介","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          { initialValue: _vm.record ? _vm.record.description : undefined } ]),expression:"[\n          'description',\n          { initialValue: record ? record.description : undefined },\n        ]"}],attrs:{"placeholder":"请输入 ai 对话平台简介","auto-size":{ minRows: 3 }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }