<template>
  <div>
    <div>
      <a v-text="'<< 返回列表'" @click="goBack"></a>
    </div>
    <a-button type="primary" style="margin: 10px" @click="handleEdit()">
      <a-icon type="plus" />
    </a-button>
    <a-table
      :dataSource="tableData"
      :pagination="false"
      rowKey="id"
      :columns="columns"
      :customRow="customRow"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)"><a-icon type="edit" /></a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确认删除此平台"
          ok-text="是"
          cancel-text="否"
          @confirm="handleDelete(record.id)"
        >
          <a><a-icon type="delete" /></a>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { getChatbotList, deleteChatbot, moveChatbot } from "@/api/laboratory";
import LinkDetail from "./LinkDetail.vue";
export default {
  name: "list",
  components: {},
  props: {},
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "",
          customRender: () => {
            return <a-icon type="menu" />;
          },
          width: 50,
        },
        {
          title: "ai 对话平台",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "链接地址",
          dataIndex: "url",
          lign: "center",
        },
        {
          title: "简介",
          dataIndex: "description",
          lign: "center",
        },
        {
          title: "",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
      sourceObj: undefined,
      sourceIndex: undefined,
      targetIndex: undefined,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getChatbotList().then((res) => {
        this.tableData = res.data;
      });
    },
    goBack() {
      this.$router.push({
        path: "/system/liboratory/list",
      });
    },
    customRow(record, index) {
      return {
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetIndex = index;
            this.handleMove();
          },
        },
      };
    },
    handleEdit(record) {
      if (record == undefined) {
        this.$dialog(
          LinkDetail,
          {
            on: {
              ok: () => {
                this.loadData();
              },
            },
          },
          {
            title: "新增 ai 对话平台",
            width: 600,
            centered: true,
            maskClosable: false,
          }
        );
      } else {
        this.$dialog(
          LinkDetail,
          {
            record,
            on: {
              ok: () => {
                this.loadData();
              },
            },
          },
          {
            title: "编辑 ai 对话平台",
            width: 600,
            centered: true,
            maskClosable: false,
          }
        );
      }
    },
    handleDelete(id) {
      deleteChatbot({ chatBotResourceId: id }).then(() => {
        this.loadData();
      });
    },
    handleMove() {
      this.tableData.splice(this.sourceIndex, 1);
      this.tableData.splice(this.targetIndex, 0, this.sourceObj);
      moveChatbot(
        this.tableData.map((item,index) => {
          return {
            chatBotResourceId: item.id,
            seq: index,
          };
        })
      ).finally(() => {
        this.loadData()
      })
    },
  },
};
</script>

<style scoped></style>
