<template>
  <div>
    <a-form :form="form">
      <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          placeholder="请输入 ai 对话平台名称"
          v-decorator="[
            'name',
            {
              rules: [
                { required: true, min: 1, message: '请输入 ai 对话平台名称！' },
              ],
              initialValue: record ? record.name : undefined,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="链接" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          placeholder="请输入 ai 对话平台链接"
          v-decorator="[
            'url',
            {
              rules: [
                {
                  required: true,
                  min: 1,
                  message: '请输入 ai 对话平台链接！',
                },
              ],
              initialValue: record
                ? record.url.startsWith('http')
                  ? record.url.split('//')[1]
                  : record.url
                : undefined,
            },
          ]"
        >
          <a-select v-model="protocol" slot="addonBefore">
            <a-select-option value="https://"> https:// </a-select-option>
            <a-select-option value="http://"> http:// </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item label="简介" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea
          placeholder="请输入 ai 对话平台简介"
          v-decorator="[
            'description',
            { initialValue: record ? record.description : undefined },
          ]"
          :auto-size="{ minRows: 3 }"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { addChatbot, editChatbot } from "@/api/laboratory";
export default {
  name: "LinkDetail",
  components: {},
  props: {
    record: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      protocol: this.record
        ? this.record.url.startsWith("http://")
          ? "http://"
          : "https://"
        : "https://",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          let formData = { ...values };
          if(formData.url.startsWith('http://')) {
            this.protocol = "http://"
          }else if(formData.url.startsWith('https://')) {
            this.protocol = "https://"
          }else{
            formData.url = this.protocol + formData.url
          }
          if (this.record && this.record.id) {
            formData.id = this.record.id;
            editChatbot(formData).then(() => {
              resolve(true);
            });
          } else {
            addChatbot(formData).then(() => {
              resolve(true);
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
